.SingleQuest-module__singleFaqContainer {
  border: solid 1px #eeecec;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  border-radius: 10px;
}

.SingleQuest-module__singleFaqContainer > strong {
  color: var(--blueColor);
  font-size: 16px;
  font-weight: bold;
  display: block;
  cursor: pointer;
  position: relative;
}

.SingleQuest-module__singleFaqContainer > strong::after {
  content: "";
  background-image: url(https://cdn-frontend-r2.syarah.com/prod/static/media/arrow_down.14bc3584.svg);
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 12px;
  height: 7px;
  transition: 0.5s;
  top: calc(50% - 7px / 2);
}

.SingleQuest-module__singleFaqContainer.SingleQuest-module__expanded > strong::after {
  transform: rotate(180deg);
}

body[dir="rtl"] .SingleQuest-module__singleFaqContainer > strong::after {
  left: 0;
}

body[dir="ltr"] .SingleQuest-module__singleFaqContainer > strong::after {
  right: 0;
}

.SingleQuest-module__singleFaqContainer.SingleQuest-module__expanded > strong {
  margin-bottom: 20px;
}

.SingleQuest-module__singleFaqContainer > p {
  color: #484848;
  font-size: 12px;
  line-height: 28px;
}

.SingleQuest-module__singleFaqContainer + .SingleQuest-module__singleFaqContainer {
  margin-top: 20px;
}

.SingleQuest-module__faqsPage > strong {
  font-size: 20px;
  line-height: 32px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.SingleQuest-module__faqsPage > p {
  font-size: 16px;
  line-height: 28px;
  color: #484848;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.SingleQuest-module__singleFaqContainer {
  border: solid 1px #eeecec;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  border-radius: 10px;
}

.SingleQuest-module__singleFaqContainer.SingleQuest-module__expanded > strong::after {
  transform: rotate(180deg);
}

@media screen and (max-width: 1024px) {
  .SingleQuest-module__faqsPage > strong {
    font-size: 14px;
    line-height: 24px;
  }

  .SingleQuest-module__faqsPage > p {
    font-size: 14px;
    line-height: 24px;
  }
  .SingleQuest-module__StyleMod {
    background-color: #f4f6f9;
  }
  .SingleQuest-module__StyleMod > strong,
  .SingleQuest-module__StyleMod > p {
    font-size: 12px;
    line-height: 24px;
  }
}
