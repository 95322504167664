.FAQV2-module__faqContainer {
  background-color: #f4f9fe;
  padding-bottom: 40px;
}

.FAQV2-module__faqContainer.FAQV2-module__homePage {
  margin-top: 70px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.FAQV2-module__allFAQCont {
  max-width: 760px;
  margin: 0 auto;
  padding-top: 40px;
}

.FAQV2-module__moreFaq {
  color: var(--blueColor);
  border: solid 1px var(--blueColor);
  border-radius: 4px;
  padding: 15px 20px;
  font-weight: bold;
  width: 190px;
  font-size: 16px;
  display: block;
  text-align: center;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 40px;
  box-sizing: border-box;
  cursor: pointer;
}

.FAQV2-module__hdrT{
  display: block;
}

@media screen and (max-width: 1024px) {
  .FAQV2-module__faqContainer.FAQV2-module__homePage {
    margin-top: 48px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .FAQV2-module__faqContainer {
    background-color: transparent;
  }
  .FAQV2-module__hdrT {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  .FAQV2-module__allFAQCont {
    padding-top: 0;
    margin-top: 27px;
  }
  .FAQV2-module__mobLink{
    color: #154F9C;
    font-size: 12px;
    line-height: 24px;
    margin-top: 20px;
    display: block;
    text-decoration: underline;
    text-align: center;

  }
}

.FAQV2-module__faqPageWrapper .FAQV2-module__faqContainer {
    background-color: #fff;
}

